<template>
  <section class="tiny padded bordered main">
    <h1><translate translate-context="*/*/*/Verb">Request a password reset</translate></h1>
    <p>
      <translate translate-context="*/*/*">Use the form below to request a password reset.</translate>
    </p>
    <form @submit.prevent="login">
      <div class="success message" v-if="success">
        <p>
          <translate translate-context="*/*/Form.Message">
            If your email was present in our database, you should receive an email shortly with instructions to reset your password. Please check your spam folder if the message doesn't arrive.
          </translate>
        </p>
        <router-link to="/login">
          <translate translate-context="*/Field/Label">Go back to login</translate>
        </router-link>
      </div>
      <div class="required field">
        <label for="email">
          <translate translate-context="*/Field/Label">Email</translate>
        </label>
        <field-errors :errors="errors.fieldErrors" field="email" />
        <input type="email" name="email" id="email" autofocus v-model="email" required>
      </div>
      <app-button :is-loading="isLoading" type="submit">
        <translate translate-context="*/Button/Verb">Submit</translate>
      </app-button>
    </form>
  </section>
</template>

<script>
import http from '@/http'

export default {
  props: ["next", "logout"],
  data () {
    return {
      errors: {},
      isLoading: false,
      email: "",
      success: false,
    }
  },
  methods: {
    async login () {
      this.success = false
      this.isLoading = true
      this.errors = {}
      try {
        await http.post('it/accounts/reset-password', {email: this.email})
        this.success = true
      } catch (e) {
        this.errors = e.backendErrors
      } finally {
        this.isLoading = false
      }

    }
  }
}
</script>
