<template>
  <section v-if="$store.state.authenticated" class="padded bordered narrow main container">
    <h1><translate translate-context="*/Title">Your account</translate></h1>
    <button @click.prevent="logout"><translate translate-context="*/Button/Verb">Logout</translate></button>
    <form @submit.prevent="updateProfile">
      <div class="success message" role="alert" v-if="profile.success">
        <p>
          <translate translate-context="*/*/Form.Message">
            Your account information was updated successfully.
          </translate>
        </p>
      </div>
      <field-errors :errors="profile.errors.nonFieldErrors" />

      <div class="stackable row">
        <div class="required field">
          <label for="profile-first-name">
            <translate translate-context="*/*/*">
              First name
            </translate>    
          </label>
          <field-errors :errors="profile.errors.fieldErrors" field="first_name" />
          <input
            id="profile-first-name"
            name="profile-first-name"
            type="text"
            required
            v-model="profile.first_name"
          >
        </div>
        <div class="field">
          <label for="profile-last-name">
            <translate translate-context="*/*/*">
              Last name
            </translate>    
          </label>
          <field-errors :errors="profile.errors.fieldErrors" field="last_name" />
          <input
            id="profile-last-name"
            name="profile-last-name"
            type="text"
            v-model="profile.last_name"
          >
        </div>
      </div>
      <hr class="hidden">
      <div class="stackable row">
        <div class="field">
          <label for="profile-email">
            <translate translate-context="*/*/*">
              Email address
            </translate>    
          </label>
          <input
            id="profile-email"
            name="profile-email"
            type="email"
            disabled
            v-model="$store.state.user.email"
          >
        </div>
        <div class="field">
          <label for="profile-password">
            <translate translate-context="*/*/*">
              New password
            </translate>    
          </label>
          <field-errors :errors="profile.errors.fieldErrors" field="password" />
          <input
            id="profile-password"
            name="profile-password"
            type="password"
            v-model="profile.password"
            :placeholder="labels.passwordPlaceholder"
          >
        </div>
      </div>

      <hr class="hidden">
      <app-button :is-loading="profile.isUpdating" type="submit">
        <translate translate-context="*/Button/Verb">Update profile</translate>
      </app-button>
    </form>
    <h2><translate translate-context="*/*/*">API Keys</translate></h2>
    <p>
      <translate translate-context="*/Paragraph">
        You can create and use keys to interact with our APIs and perform actions in your own scripts and softwares.
      </translate>
    </p>
    <router-link to="/help/api">
      <translate translate-context="*/Button/Verb">
        Browse the API documentation
      </translate>
    </router-link>
    <h3 id="api"><translate translate-context="*/*/*">Create a new API Key</translate></h3>
    <form @submit.prevent="createApiKey">
      <field-errors :errors="apiKey.errors.nonFieldErrors" />
      <div class="field">
        <label for="api-key-name">
          <translate translate-context="*/*/*">
            Name (optional)
          </translate>    
        </label>
        <input
          id="api-key-name"
          name="api-key-name"
          type="text"
          v-model="apiKey.name"
          placeholder="API Key for myscript.sh">
      </div>
      <app-button :is-loading="apiKey.isCreating" type="submit">
        <translate translate-context="*/Button/Verb">Submit</translate>
      </app-button>
      <div class="success message" role="alert" v-if="apiKey.latest">
        <p>
          <translate translate-context="*/*/Form.Message">
            Your API Key was successfully created.
          </translate>
          <strong>
            <translate translate-context="*/*/Form.Message">
              Please copy it from the field below and store it in a safe place. You won't be able to see it again.
            </translate>
          </strong>
        </p>
        <input
          type="text"
          :value="apiKey.latest.key"
          style="width: 100%"
          readonly>
      </div>
    </form>
    <loading-area class="opaque padded bordered mt-regular" v-if="apiKey.isLoading" :is-loading="apiKey.isLoading">
      <span><translate translate-context="*/*/Loader">Loading API Keys…</translate></span>
    </loading-area>
    <template v-else-if="apiKey.existing.length > 0">
      <h3><translate translate-context="*/*/*">Existing keys</translate></h3>
      <table>
        <thead>
          <th>
            <translate translate-context="*/*/*">Name</translate>
          </th>
          <th>
            <translate translate-context="*/*/*">Creation date</translate>
          </th>
          <th>
            <translate translate-context="*/*/*">Last use</translate>
          </th>
          <th></th>
        </thead>
        <tbody>
          <tr v-for="key in apiKey.existing" :key="key.creation_date">
            <td>
              <template v-if="key.description">{{ key.description }}</template>
              <template v-else>N/A</template>
            </td>
            <td>
              {{ ago(key.creation_date) }}
            </td>
            <td>
              <template v-if="key.last_used_date">{{ ago(key.last_used_date) }}</template>
              <template v-else>N/A</template>
            </td>
            <td>
              <button class="link" @click.prevent="apiKey.toDelete = key; apiKey.showDeleteModal = true">
                <translate translate-context="*/*/*">Revoke…</translate>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <app-modal v-if="apiKey.showDeleteModal" @close="apiKey.showDeleteModal = false" :submit-action="deleteApiKey">
        <template v-slot:header>
          <h3>
            <translate translate-context="*/*/*" :translate-params="{name: apiKey.toDelete.description || 'N/A'}">Revoke API Key "%{ name }"</translate>
          </h3>
        </template>
        <p>
          <translate translate-context="*/*/*">The API Key will be removed from our database and become unusable. Software and scripts using this key won't be able to contact our APIs anymore.</translate>
        </p>
        <p>
          <strong>
            <translate translate-context="*/*/*">This action is irreversible, please proceed with caution.</translate>
          </strong>
        </p>
        <template v-slot:action-button>
          <app-button :is-loading="apiKey.isDeleting" @click.prevent="deleteApiKey">
            <translate translate-context="*/Button/Verb">Revoke</translate>
          </app-button>
        </template>
    </app-modal>
    </template>
  </section>
</template>

<script>
import http from '@/http'
import { ago } from '@/time'

export default {
  data () {
    return {
      ago,
      apiKey: {
        name: "",
        latest: null,
        errors: {},
        existing: [],
        isCreating: false,
        isLoading: false,
        loadingErrors: {},
        isDeleting: false,
        toDelete: null,
        showDeleteModal: false,
      },
      profile: {
        first_name: this.$store.state.user.first_name,
        last_name: this.$store.state.user.last_name,
        password: "",
        errors: {},
        isUpdating: false,
        success: false,
      },
    }
  },
  async created () {
    await this.fetchApiKeys()
  },
  computed: {
    labels () {
      return {
        passwordPlaceholder: this.$pgettext("*/*/*", "Leave empty to keep current password")
      }
    }
  },
  methods: {
    async logout () {
      this.$router.push('/login?logout=true')
    },
    async fetchApiKeys() {
      let response
      this.apiKey.latest = null
      this.apiKey.isLoading = true
      this.apiKey.existing = []
      this.apiKey.loadingErrors = {}
      try {
        response = await http.get('api-keys')
        this.apiKey.existing = response.data.results
      } catch (e) {
        this.apiKey.isLoading = e.backendErrors
      } finally {
        this.apiKey.isLoading = false
      }
    },
    async createApiKey () {
      this.apiKey.isCreating = true
      this.apiKey.errors = {}
      this.apiKey.latest = null
      let data = {
        description: this.apiKey.name || null
      }

      try {
        let response = await http.post(`api-keys`, data)
        this.apiKey.latest = response.data
        this.apiKey.existing.unshift(response.data)
      } catch (e) {
        this.apiKey.errors = e.backendErrors
      } finally {
        this.apiKey.isCreating = false
      }
    },
    async deleteApiKey () {
      if (!this.apiKey.toDelete) {
        return
      }
      this.apiKey.isDeleting = true
      try {
        await http.delete(`api-keys/${this.apiKey.toDelete.id}`)
        await this.fetchApiKeys({})
      } finally {
        this.apiKey.isDeleting = false
        this.apiKey.toDelete = null
        this.apiKey.showDeleteModal = false
      }
    },
    async updateProfile () {
      this.profile.isUpdating = true
      this.profile.errors = {}
      this.profile.success = false

      let data = {
        first_name: this.profile.first_name,
        last_name: this.profile.last_name,
        password: this.profile.password,
      }
      try {
        let response = await http.patch(`profile`, data)
        this.profile.success = true
        this.$store.commit("user", response.data)
      } catch (e) {
        this.profile.errors = e.backendErrors
      } finally {
        this.profile.isUpdating = false
      }
    },
  }
}
</script>
