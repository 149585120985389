<template>
  <section></section>
</template>

<script>

export default {
  components: {},
  created () {
    this.$router.push({name: 'dashboard', params: {group: this.$store.state.selectedGroupId}})
  }
}
</script>
