<template>
  <section class="tiny padded bordered main">
    <h1><translate translate-context="*/*/*/Verb">Login</translate></h1>
    <form @submit.prevent="login">
      <field-errors :errors="errors.nonFieldErrors" />
      <div class="required field">
        <label for="username">
          <translate translate-context="*/Field/Label">Email</translate>
        </label>
        <input type="email" name="username" id="username" autofocus v-model="username">
      </div>
      <div class="required field">
        <label for="password">
          <translate translate-context="*/Field/Label">Password</translate>
        </label>
        <input type="password" id="password" name="password" v-model="password">
      </div>
      <div class="controls">
        <app-button :is-loading="isLoading" type="submit">
          <translate translate-context="*/Button/Verb">Submit</translate>
        </app-button>
        <router-link to="/password-reset">
          <translate translate-context="*/Button/Verb">Do you have trouble logging in?</translate>
        </router-link>
      </div>
    </form>
  </section>
</template>

<script>
import http from '@/http'

export default {
  props: ["next", "logout"],
  data () {
    return {
      errors: {},
      isLoading: false,
      username: "",
      password: "",
    }
  },
  async created () {
    if (this.logout) {
      await this.$store.dispatch('logout')
    }
    if (this.$store.state.authenticated) {
      this.$router.push(this.next)
    }
  },
  methods: {
    async login () {
      let response
      this.isLoading = true
      this.errors = {}
      try {
        response = await http.post('login', {username: this.username, password: this.password})
        await this.$store.dispatch('handleLogin', response.data)
        // fetch user groups to have proper filtering in UI
        await this.$store.dispatch("fetchGroups", http)
        await this.$store.dispatch("fetchOrganization", http)

        if (this.$store.state.user.onboarding_complete === false) {
          this.$router.push('/onboarding')
        } else {
          this.$router.push(this.next)
        }
      } catch (e) {
        this.errors = e.backendErrors
      } finally {
        this.isLoading = false
      }

    }
  }
}
</script>
